<template>
    <div class="container" style="background-color: #FCFCFC">
        <div class="d-flex flex-column flex-lg-row align-items-center gap-3">
            <div class="d-none d-md-block">
                <div
                    class="position-relative d-none d-lg-block"
                    v-if="bannerThree"
                >
                    <img
                        :src="bannerThree.photo.file_url"
                        class="d-block w-100"
                        alt="slide image"
                    />
                    <div class="text position-absolute">
                        <p class="w-50" style="color: #775A07">
                            {{ bannerThree.title }}
                        </p>
                        <h1 class="w-75 fw-bolder" style="color: #775A07;">
                            {{ bannerThree.description }}
                        </h1>
                        <main-button
                            name="GET REWARD NOW"
                            style="background-color:#775A07"
                            @clicked="goToPointsPage"
                        />
                    </div>
                </div>
            </div>
            <!--  -->
            <point-leaderboard
                class="point-leaderboard"
                :loyalityData="loyalityData"
                :miningData="miningData"
                :referalData="referalData"
            />
            <!--  -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LeaderBoardAllGql from '@/graphql/queries/point/LeaderBoardAll.gql';
import PointLeaderBoard from '../../components/points/PointLeaderBoard.vue';
import BannerSlide from '@/graphql/queries/home/BannerSlide.gql';
import Button from '../../components/reusable/Button.vue';

export default {
    data() {
        return {
            loyalityData: [],
            miningData: [],
            referalData: [],
            bannerThree: null,
        };
    },
    components: {
        'point-leaderboard': PointLeaderBoard,
        'main-button': Button,
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
        }),
    },
    mounted() {
        this.getLeaderBoardShweOffline();
        this.getLeaderBoardNgweOffline();
        this.getLeaderBoardReferOffline();
        this.getArrangeBanners();
    },
    watch: {
        user() {
            if (this.user && this.user.id) {
                this.getLeaderBoardShweOffline();
                this.getLeaderBoardNgweOffline();
                this.getLeaderBoardReferOffline();
            }
        },
    },
    methods: {
        getArrangeBanners() {
            this.$apollo
                .query({
                    query: BannerSlide,
                })
                .then(response => {
                    // console.log('arrangethree', response.data.webbanners[3]);
                    // this.bannerThree = response.data.webbanners[3];
                    let bannerArray = response.data.webbanners;
                    bannerArray.map(banner => {
                        if (banner.arrange === 3) {
                            this.bannerThree = banner;
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getLeaderBoardShweOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'product_point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.loyalityData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getLeaderBoardNgweOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.miningData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getLeaderBoardReferOffline() {
            this.$apollo
                .query({
                    query: LeaderBoardAllGql,

                    variables: {
                        field_name: 'referral_point',
                    },
                })
                .then(response => {
                    // console.log(this.user, 'user');
                    this.referalData = response.data.leaderboardsall;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        goToPointsPage() {
            this.$router.push({
                name: 'Points',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.text {
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
}
.point-leaderboard {
    width: 100%;
}
@media (min-width: 576px) {
    .point-leaderboard {
        width: 30%;
    }
}
</style>
