<template>
    <div class="container d-flex flex-column py-5">
        <div class="position-relative d-none d-md-block" v-if="bannerOne">
            <img
                :src="bannerOne.photo.file_url"
                class="d-block w-100"
                alt="slide image"
            />
            <div class="text position-absolute">
                <h1 class="w-75 fw-bolder" style="color: #775A07;">
                    {{ bannerOne.title }}
                </h1>
                <p class="w-50" style="color: #775A07;">
                    {{ bannerOne.description }}
                </p>
                <main-button
                    name="View All"
                    style="background-color:#775A07"
                    @clicked="bannerBtn('bannerOne')"
                />
            </div>
        </div>
        <div class="row mt-5" v-if="bannerTwo">
            <!-- image -->
            <div class="col d-none d-xl-block">
                <img
                    :src="bannerTwo.photo.file_url"
                    class="w-100 rounded"
                    alt="slide image"
                />
            </div>
            <div class="text-center col w-100">
                <!--text -->
                <div class="mb-3">
                    <h1 class="fw-bolder" style="color: #775A07;">
                        {{ bannerTwo.title }}
                    </h1>
                    <p style="color: #775A07;">
                        {{ bannerTwo.description }}
                    </p>
                    <main-button
                        name="View All"
                        @clicked="bannerBtn('bannerTwo')"
                    />
                </div>
                <div class="banner-product">
                    <div v-for="(product, index) in last_previews" :key="index">
                        <img
                            :src="product.photo.thumbnail_url"
                            :alt="product.title"
                            @click="toDetailPage(product.slug)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import AddToWishList from '@/mixins/AddToWishlist';
import Button from '../reusable/Button';
import BannerSlide from '@/graphql/queries/home/BannerSlide.gql';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

export default {
    mixins: [encryptDecrypt, AddToWishList],
    components: {
        'main-button': Button,
    },
    data() {
        return {
            bannerOne: null,
            bannerTwo: null,
            last_previews: [],
        };
    },
    mounted() {
        this.getArrangeBanners();
    },
    methods: {
        getArrangeBanners() {
            this.$apollo
                .query({
                    query: BannerSlide,
                })
                .then(response => {
                    // console.log('arrangeone', response.data.webbanners[1]);
                    // this.bannerOne = response.data.webbanners.arrange[1];
                    // this.bannerTwo = response.data.webbanners[2];
                    // this.last_previews =
                    //     response.data.webbanners[2].collection.products.data;
                    let bannerArray = response.data.webbanners;
                    bannerArray.map(banner => {
                        if (banner.arrange === 1) {
                            this.bannerOne = banner;
                        }
                        if (banner.arrange === 2) {
                            this.bannerTwo = banner;
                            this.last_previews =
                                banner.collection.products.data;
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        bannerBtn(banner) {
            this.$router.push({
                name: 'ProductList',
                query: {
                    collection_id: this.encryptAES(
                        banner === 'bannerOne'
                            ? this.bannerOne.collection.id
                            : this.bannerTwo.collection.id,
                        'pss',
                    ),
                    collection_name:
                        banner === 'bannerOne'
                            ? this.bannerOne.collection.name
                            : this.bannerTwo.collection.name,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    .text {
        top: 50%;
        left: 35%;
        transform: translate(-50%, -50%);
    }
}
.banner-product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    img {
        width: 10rem;
        margin-bottom: 1rem;
        border-radius: 5px;
    }
}
@media (min-width: 768px) {
    .banner-product {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 80%;
        margin: 0 auto;
        gap: 0.5rem;
        img {
            width: 14rem;
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    h1 {
        font-size: 3vw;
    }
    p {
        font-size: 1vw;
    }
}
</style>
