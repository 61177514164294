<template>
    <div>
        <bg-slide />
        <category-area />
        <promotion-sales />
        <new-arrival />
        <gallery-post />
        <trending-jewellery />
        <blog-post />
        <point-component />
        <shop-location />
        <footer-section />
        <!-- <our-store /> -->
        <!-- <shop-by-gender /> -->
        <!-- <top-seller /> -->
        <!-- <mobile-banner></mobile-banner> -->
        <!-- <shop-by-collection /> -->
        <!-- <main-footer /> -->
    </div>
</template>

<script>
import BgSlide from '../components/home/BgSlide';
import CategoryArea from '../components/home/CategoryArea';
import NewArrival from '../components/home/NewArrival.vue';
import PromotionSales from '../components/home/PromotionSales.vue';
import Gallery from '../components/home/Gallery.vue';
import TrendingJewellery from '../components/home/TrendingJewellery.vue';
import Blog from '../components/home/Blog.vue';
import Point from '../components/home/Point.vue';
import ShopLocation from '../components/home/ShopLocation.vue';
import Footer from '../components/home/Footer';
// import OurStore from '../components/home/OurStore.vue';
// import ShopByGender from '../components/home/ShopByGender';
// import CategoryArea from '../components/home/CategoryArea';
// import Footer from '../components/home/Footer.vue';
// import ShopByCollection from '../components/home/ShopByCollection';
// import TopSeller from '../components/home/TopSeller';
// import MobileBanner from '../components/mobile-banner/MobileBanner.vue';

export default {
    components: {
        'bg-slide': BgSlide,
        'category-area': CategoryArea,
        'new-arrival': NewArrival,
        'footer-section': Footer,
        'promotion-sales': PromotionSales,
        'gallery-post': Gallery,
        'trending-jewellery': TrendingJewellery,
        'blog-post': Blog,
        'point-component': Point,
        'shop-location': ShopLocation,
        // 'our-store': OurStore,
        // 'shop-by-gender': ShopByGender,
        // 'top-seller': TopSeller,
        // 'main-footer': Footer,
        // 'shop-by-collection': ShopByCollection,
        // 'mobile-banner': MobileBanner,
    },
};
</script>

<style lang="scss" scoped>
.category-area .swiper-slide,
.arrival-area .swiper-slide,
.collection-area .swiper-slide {
    padding-top: unset !important;
}
</style>
