<template>
    <div class="container">
        <h1
            class="mt-5 arrival-title"
            style="font-size: calc(100% + 1vw + 1vh);font-weight: bold"
        >
            {{ $t('home.newArrivalArea.header') }}
        </h1>
        <div
            class="d-flex flex-wrap align-items-start justify-content-center justify-content-md-start mt-4 pb-4 pt-2"
            style="gap: 1rem"
        >
            <div class="card" v-for="(product, index) in products" :key="index">
                <div>
                    <img
                        :src="product.cover_photo"
                        :alt="product.title"
                        class="card-img-top cursor"
                        @click="toDetailPage(product.slug)"
                    />
                    <font-awesome-icon
                        v-show="user"
                        @click="toggleWish(product.id)"
                        :icon="['fas', 'heart']"
                        class="icon position-absolute rounded-circle bg-light"
                        :class="{ 'wish-list': hasInWishList(product.id) }"
                        :id="`active-wish-${product.id}`"
                    />
                    <div class="my-2 mx-2" @click="toDetailPage(product.slug)">
                        <p class="arrival-body" style="font-size: 14px;">
                            {{ product.title }}
                        </p>
                        <!-- <div class="d-flex gap-2">
                            <p class="arrival-body fw-bold">
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0].final_price,
                                    )
                                }}
                                MMK
                            </p>
                            <p
                                class="sold-out text-center"
                                v-if="soldOutedProducts.includes(product.id)"
                            >
                                Out of Stock
                            </p>
                        </div>
                         -->
                        <div class="d-flex flex-column flex-lg-row gap-1">
                            <!-- <p
                                class="arrival-body fw-bold"
                                v-if="
                                    product.productvariants[0].discounted_price
                                "
                            >
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0]
                                            .discounted_price,
                                    )
                                }}
                                MMK
                            </p> -->
                            <p
                                v-if="discountedVariant(product)"
                                class="arrival-body fw-bold"
                            >
                                {{
                                    addCommaForPrice(
                                        discountedVariant(product)
                                            .discounted_price,
                                    )
                                }}
                                MMK
                            </p>
                            <p
                                class="sold-out text-center"
                                v-if="soldOutedProducts.includes(product.id)"
                            >
                                Out of Stock
                            </p>
                        </div>
                        <div class="d-flex flex-column flex-lg-row gap-1">
                            <!-- <p
                                class="arrival-body"
                                :class="
                                    product.productvariants[0].discounted_price
                                        ? 'text-decoration-line-through'
                                        : 'fw-bold fs-6'
                                "
                                style="font-size: 14px;"
                                v-if="product.discounted_type != 0"
                            >
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0].final_price,
                                    )
                                }}
                                MMK
                            </p> -->
                            <p
                                class="arrival-body"
                                :class="
                                    discountedVariant(product)
                                        ? 'text-decoration-line-through'
                                        : 'fw-bold fs-6'
                                "
                                style="font-size: 14px;"
                            >
                                {{
                                    addCommaForPrice(
                                        originalVariantPrice(product),
                                    )
                                }}MMK
                            </p>
                            <!-- <p
                                class="arrival-body"
                                style="font-size: 14px;"
                                v-if="
                                    product.productvariants[0]
                                        .discounted_type != 0
                                "
                            >
                                {{
                                    product.productvariants[0].discounted_amount
                                }}
                                {{
                                    product.productvariants[0]
                                        .discounted_type == 1
                                        ? '%'
                                        : 'MMK'
                                }}
                                OFF
                            </p> -->
                            <p
                                class="arrival-body"
                                style="font-size: 14px;"
                                v-if="discountedVariant(product)"
                            >
                                {{
                                    discountedVariant(product).discounted_amount
                                }}
                                {{
                                    discountedVariant(product)
                                        .discounted_type == 1
                                        ? '% OFF'
                                        : 'MMK OFF'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <main-button
                :name="$t('home.newArrivalArea.button')"
                @clicked="toListPage('newArrivals')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Slide from '@/utils/Slide';
import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';
import Button from '../reusable/Button';

// apollo queries
import NewArrivalProducts from '@/graphql/queries/home/NewArrivalProducts.gql';

export default {
    mixins: [Slide, CommaPrice, AddToWishList],
    components: {
        'main-button': Button,
    },
    data() {
        return {
            soldOutedProducts: [],
            wishListColor: false,
        };
    },

    mounted() {
        this.$nextTick(function() {
            this.getNewArrivalProducts();
        });
        null;
    },
    computed: {
        ...mapGetters({
            wishlists: 'wishlist/getWishlists',
            products: 'home/getNewArrivals',
            user: 'auth/getUser',
        }),
    },

    methods: {
        originalVariantPrice(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                )?.final_price ??
                prd.productvariants.find(pv => pv.total_in_stock > 0)
                    ?.final_price ??
                prd.productvariants[0]?.final_price
            );
        },
        discountedVariant(prd) {
            return (
                prd.productvariants.find(pv => {
                    let pro = pv.latestPromotion;
                    if (pro) {
                        if (
                            pro.status === 'active' &&
                            new Date(pro.start_date) <= new Date() &&
                            new Date(pro.end_date) >= new Date()
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return pv.discounted_type != 0;
                    }
                }) || null
            );
        },
        // 'upcomingPromotion() {
        //     let upcomingPro = this.selectedVariant.latestPromotion;
        //     if (upcomingPro && new Date(upcomingPro.start_date) >= new Date()) {
        //         return upcomingPro;
        //     } else {
        //         return null;
        //     }
        // },
        getNewArrivalProducts() {
            this.$apollo
                .query({
                    fetchPolicy: 'no-cache',
                    query: NewArrivalProducts,
                    variables: {
                        first: 10,
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'home/ADD_NEWARRIVALS',
                        response.data.newArrivalProducts.data,
                    );
                    const products = response.data.newArrivalProducts.data;
                    products.map(product => {
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock <= 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        toDetailPage(slug) {
            this.$router.push({
                name: 'ProductVariantDetail',
                params: {
                    slug: slug,
                },
            });
        },
        toListPage(name) {
            this.$router.push({
                name: 'ProductList',
                query: {
                    name: name,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    width: 10rem;
    // margin: 0 0.5rem 0.5rem 0rem;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    .icon {
        cursor: pointer;
        color: rgb(220, 217, 217);
        padding: 6px;
        width: 1.8rem;
        height: 1.8rem;
        top: 10px;
        right: 0px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 10rem;
    }
    .arrival-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .sold-out {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 50px;
        background: transparent
            linear-gradient(102deg, #e43225 0%, #ff5400 100%) 0% 0% no-repeat
            padding-box;
    }
}
.arrival-title {
    text-align: center;
}
// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .arrival-title {
        text-align: left;
    }
}
@media (min-width: 576px) {
    .card {
        width: 15rem;
        img {
            height: 12rem;
        }
    }
}
</style>
