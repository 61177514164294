<template>
    <!-- horizontal scroll -->
    <div class="container">
        <h1
            class="mt-3 category-title"
            style="font-size: calc(100% + 1vw + 1vh);font-weight: bold"
        >
            {{ $t('home.categoryArea.header') }}
        </h1>
        <div class="scrolling-wrapper row gx-0 flex-row mt-4 pb-4 pt-2">
            <!-- <div
                class="card"
                v-for="(category, index) in categories"
                :key="index"
                @click="toCategoryProductList(category.id)"
            > -->
            <div
                class="card"
                v-for="(category, index) in categories"
                :key="index"
            >
                <img
                    v-if="category.cover_photo"
                    class="card-img-top"
                    :src="category.cover_photo"
                    :alt="category.name"
                    @click="toProduct(category)"
                />
                <img
                    v-else
                    class="card-img-top"
                    src="@/assets/img/category-slide/Group 1295.png"
                />
                <!-- <p
                    class="text-center"
                    style="margin-top: 16px;"
                    @click="toListPage(category.id)"
                > -->
                <p class="text-center" style="margin-top: 16px;">
                    {{ category.name }}
                </p>
                <!-- <p class="text-center" style="margin-top: 16px;">
                    <router-link
                        class="dropdown-item fw-normal bg-transparent"
                        :to="{
                            name: 'ProductList',
                            query: {
                                category_id: encryptAES(category.id, 'pss'),
                                category_name: category.name,
                            },
                        }"
                        >{{ category.name }}</router-link
                    >
                </p> -->
            </div>
        </div>
    </div>

    <!-- no scroll -->
    <!-- <div class="container">
        <h1 class="mt-5" style="font-size : 42px;font-weight: bold">
            {{ $t('home.categoryArea.header') }}
        </h1>
        <div class="d-flex flex-wrap justify-content-around mt-4 pb-4 pt-2">
            <div
                class="card"
                v-for="(category) in categories"
                :key="category.name"
            >
                <img
                    class="card-img-top"
                    :src="category.photos[0].thumbnail_url"
                    :alt="category.name"
                />
                <p class="text-center" style="margin-top: 16px;">
                    {{ category.name }}
                </p>
            </div>
                <img
                    class="card-img-top"
                    src="@/assets/img/category-slide/Group 1295.png"
                />
                <p class="text-center" style="margin-top: 16px;">
                    Item
                </p>
        </div>
    </div> -->
</template>

<script>
import { mapGetters } from 'vuex';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

export default {
    mixins: [encryptDecrypt],

    updated() {
        // Todo: initialize swiper
        // this.slideInit();
    },

    computed: {
        ...mapGetters({
            categories: 'home/getCategories',
        }),
    },

    methods: {
        toProduct(productCategory) {
            this.$router.push({
                name: 'ProductList',
                query: {
                    category_id: this.encryptAES(productCategory.id, 'pss'),
                    category_name: productCategory.name,
                },
            });
        },
        slideInit() {
            this.initSlide('.category-area', 5, 10, 'horizontal');
        },

        // toListPage(id) {
        //     if (window.outerWidth < 767.98) {
        //         this.$router.push({
        //             name: 'ProductList',
        //             params: {
        //                 id: id,
        //             },
        //             query: {
        //                 category_id: id,
        //             },
        //         });
        //     } else {
        //         this.$router.push({
        //             name: 'ProductList',
        //             query: {
        //                 category_id: id,
        //             },
        //         });
        //     }
        // },
        // toCategoryProductList(id) {
        //     this.$router.push({
        //         name: 'ProductList',
        //         query: {
        //             category_id: id,
        //         },
        //     });
        // },
    },
};
</script>

<style lang="scss" scoped>
.scrolling-wrapper {
    justify-content: center;
    gap: 1rem;
}
.card {
    width: 10rem;
    // margin: 0 1rem 1rem 0;
    transition: all 0.2s ease-in-out !important;
    cursor: pointer;
    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    img {
        height: 10rem;
    }
}
.category-title {
    text-align: center;
}
@media (min-width: 576px) {
    .scrolling-wrapper {
        gap: 0rem;
        justify-content: start;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .card {
        width: 15rem;
        margin: 0 1rem 0 0;
        img {
            height: 12rem;
        }
    }
}
// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .category-title {
        text-align: left;
    }
}
</style>
