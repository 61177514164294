<template>
    <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
    >
        <div
            class="carousel-indicators"
            v-if="heroBanner && heroBanner.length > 0"
        >
            <button
                v-for="(banner, index) in heroBanner"
                :key="index"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                :aria-current="[index === 0 ? true : false]"
                :aria-label="`Slide ${index + 1}`"
                :data-bs-slide-to="index"
                :class="[index === 0 ? 'active' : '']"
            ></button>
        </div>
        <div v-else class="carousel-indicators">
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
            ></button>
        </div>
        <div class="carousel-inner" v-if="heroBanner && heroBanner.length > 0">
            <div
                class="carousel-item"
                :class="[index == 0 ? 'active' : '']"
                v-for="(banner, index) in heroBanner"
                :key="index"
            >
                <img
                    :src="banner.photo.file_url"
                    class="d-block w-100 mobile-responsive"
                    alt="slide image"
                />
            </div>
        </div>
        <div
            v-else-if="loading"
            class="d-flex align-items-center justify-content-center"
            style="height:75vh; background:#d6d6d6;opacity: 0.5"
        >
            <div class="spinner-border" role="status"></div>
        </div>
        <div v-else class="carousel-inner">
            <div class="carousel-item active">
                <img
                    src="@/assets/img/bg-slide.png"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/img/bg-slide.png"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/img/bg-slide.png"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BannerSlide from '@/graphql/queries/home/BannerSlide.gql';

export default {
    components: {},
    data() {
        return {
            heroBanner: [],
            loading: false,
        };
    },
    mounted() {
        this.getBanners();
    },

    computed: {
        ...mapGetters({
            banners: 'home/getBanners',
        }),
    },

    methods: {
        getBanners() {
            this.loading = true;
            this.$apollo
                .query({
                    query: BannerSlide,
                })
                .then(response => {
                    let bannerArray = response.data.webbanners;
                    bannerArray.map(banner => {
                        if (banner.is_hero === true) {
                            this.heroBanner.push(banner);
                        }
                    });

                    // this.$store.commit(
                    //     'home/ADD_BANNERS',
                    //     response.data.webbanners,
                    // );
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped lang="scss">
img {
    height: 75vh;
    object-fit: cover;
}
.carousel-indicators button {
    width: 50px;
    height: 7px;
    border: transparent;
    background: #fff;
    opacity: 0.7;
    border-radius: 15px;
    margin: 0 2px;
    margin-bottom: 5px;
}

.carousel-indicators .active {
    width: 20px;
    background-color: var(--color-green);
}
</style>
