<template>
    <div class="container py-5">
        <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-between"
        >
            <div>
                <h1
                    class="mt-5"
                    style="font-size: calc(100% + 1vw + 1vh);font-weight: bold"
                >
                    Videos, Blogs and Articles
                </h1>
                <p
                    class="fw-bold"
                    style="font-size: calc(100% + 0.2vw + 0.2vh)"
                >
                    <span>Inspire</span> . <span>Inform</span> .
                    <span>Imagine</span>
                </p>
            </div>
            <main-button @clicked="toBlogPost" name="View All" />
        </div>
        <div
            class="scrolling-wrapper row gx-0 flex-row flex-nowrap mt-4 pb-4 pt-2"
        >
            <div class="card" v-for="(blog, index) in blogs" :key="index">
                <router-link
                    :to="{
                        name: 'BlogDetails',
                        params: { id: blog.id, slug: blog.slug },
                    }"
                    class="text-decoration-none text-dark"
                >
                    <!-- <youtube
                        v-if="blog.video_url"
                        :video-id="getVideoId(blog.video_url)"
                        :player-width="410.5"
                        :player-height="288"
                        @ready="ready"
                        @playing="playing"
                        class="card-img-top"
                    ></youtube> -->
                    <div v-if="blog.video_url" class="video-wrap">
                        <iframe
                            width="560"
                            height="315"
                            :src="blog.video_url"
                            frameborder="0"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <img
                        v-else
                        :src="blog.photo.file_url"
                        :alt="blog.title"
                        class="card-img-top"
                    />
                    <div class="tag-name position-absolute rounded bg-light">
                        {{ blog.tag.name }}
                    </div>

                    <div class="my-2 mx-2">
                        <h1 class="fw-bold" style="font-size: 28px;">
                            {{ blog.title }}
                        </h1>
                        <p
                            class="blog-body fw-bold"
                            v-html="blog.content.substring(0, 230)"
                        />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import blogsQuery from '@/graphql/queries/blog/Blogs.gql';
import blogsByTagIdQuery from '@/graphql/queries/blog/BlogsByTagId.gql';
import blogGuidesQuery from '@/graphql/queries/blog/BlogGuides.gql';
import tagsQuery from '@/graphql/queries/tag/Tags.gql';
import { mapState, mapActions, mapGetters } from 'vuex';
// import BlogCard from '@/components/blog/Details.vue';
import Button from '../../components/reusable/Button.vue';
import { getIdFromURL } from 'vue-youtube-embed';
export default {
    components: {
        // BlogCard,
        'main-button': Button,
    },
    data() {
        return {
            blogsQuery,
            blogsByTagIdQuery,
            blogGuidesQuery,
            tagsQuery,
            selectedTagId: 'all',
            selectedTagName: 'All Blogs',
            pageNo: 1,
            count: 6,
            hasMorePages: false,
        };
    },
    created() {
        this.fetchAllBlogs();
    },
    computed: {
        ...mapState({
            buyerguideActive: state => state.blog.buyerguideActive,
        }),
        ...mapGetters({
            blogs: 'blog/getBlogs',
        }),
    },
    watch: {
        buyerguideActive: function() {
            this.pageNo = 1;
            this.checkToFetch();
        },
    },
    methods: {
        getVideoId(url) {
            return getIdFromURL(url);
        },
        ...mapActions('blog', ['showBuyerguide', 'hideBuyerguide']),
        toBlogPost() {
            this.$router.push({
                name: 'Blog',
            });
        },
        checkToFetch() {
            if (this.buyerguideActive) this.fetchGuideBlogs();
            else if (this.selectedTagId == 'all') this.fetchAllBlogs();
            else this.fetchTagBlogs();
        },
        // closeTagModal() {
        //     MicroModal.close('tagModal');
        // },

        // openTagModal() {
        //     MicroModal.show('tagModal');
        // },

        updateSelectedTag(tagValue) {
            this.pageNo = 1;

            if (tagValue == 'all') {
                this.selectedTagId = 'all';
                this.selectedTagName = 'All Blogs';
                this.fetchAllBlogs();
                this.hideBuyerguide();
            } else {
                this.selectedTagId = tagValue.id;
                this.selectedTagName = tagValue.name;
                this.fetchTagBlogs();
                this.hideBuyerguide();
            }
        },

        fetchAllBlogs() {
            this.$apollo
                .query({
                    query: gql`
                        ${blogsQuery}
                    `,
                    variables: {
                        pageNo: this.pageNo,
                    },
                })
                .then(data => {
                    const { paginatorInfo, data: blogs } = data.data.blogs;
                    this.hasMorePages = paginatorInfo.hasMorePages;
                    this.$store.commit('blog/ADD_BLOGS', {
                        blogs: blogs,
                    });
                });
        },

        fetchGuideBlogs() {
            this.$apollo
                .query({
                    query: gql`
                        ${blogGuidesQuery}
                    `,
                    variables: {
                        count: 6,
                        page: this.pageNo,
                    },
                })
                .then(data => {
                    const {
                        paginatorInfo,
                        data: blogs,
                    } = data.data.buyerGuidedBlogs;

                    this.blogs.paginatorInfo = paginatorInfo;
                    this.blogs.blogs = [...blogs];
                });
        },

        fetchTagBlogs() {
            this.$apollo
                .query({
                    query: gql`
                        ${blogsByTagIdQuery}
                    `,
                    variables: {
                        tag_id: this.selectedTagId,
                        pageNo: this.pageNo,
                    },
                })
                .then(data => {
                    const {
                        paginatorInfo,
                        data: blogs,
                    } = data.data.blogsByTagId;

                    this.blogs.paginatorInfo = paginatorInfo;
                    this.blogs.blogs = [...blogs];
                });
        },

        showPrev() {
            this.pageNo--;
            if (this.buyerguideActive) this.fetchGuideBlogs();
            else if (this.selectedTagId == 'all') this.fetchAllBlogs();
            else this.fetchTagBlogs();
        },

        showNext() {
            this.pageNo++;
            if (this.buyerguideActive) this.fetchGuideBlogs();
            else if (this.selectedTagId == 'all') this.fetchAllBlogs();
            else this.fetchTagBlogs();
        },

        showMore() {
            this.pageNo++;

            this.$apollo
                .query({
                    query: blogsQuery,

                    variables: {
                        pageNo: this.pageNo,
                    },
                })
                .then(response => {
                    const { paginatorInfo, data: blogs } = response.data.blogs;

                    this.hasMorePages = paginatorInfo.hasMorePages;
                    blogs.map(blog => {
                        this.$store.commit('blog/APPEND_BLOG', blog);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.scrolling-wrapper {
    overflow-x: auto;
}
.video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    max-width: 1920px;
    margin: 0 auto;
}
.video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1080px;
}
.card {
    width: 25rem;
    margin: 0 1rem 0 0;
    padding: 0;
    border: none;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-0.5rem);
        box-shadow: none;
        opacity: 0.9;
    }
    .tag-name {
        cursor: pointer;
        padding: 3px 5px;
        top: 10px;
        left: 10px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 15.6rem;
        border-radius: 0.5inrem;
    }
    .blog-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
}
</style>
