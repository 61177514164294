<template>
    <div class="container" v-if="activePromotion">
        <!-- {{ new Date(activePromotion.start_date) }} -->
        <h1
            class="mt-5 promotion-title"
            style="font-size: calc(100% + 1vw + 1vh);font-weight: bold"
        >
            {{ activePromotion.title }}
        </h1>
        <div
            class="d-flex flex-md-row align-items-center justify-content-between gap-3 mt-4"
            :class="remainingHours ? '' : 'flex-column'"
            style="--bs-gutter-x:2rem"
        >
            <count-down
                :end="timerProp.timer"
                showDays
                showHours
                showMinutes
                showSeconds
                :countdownText="timerProp.title"
                @hours="hours"
            ></count-down>
            <button class="btn" @click="toPromotionPage">View all</button>
        </div>
        <div class="scrolling-wrapper row gx-0 flex-row mt-4 pb-4 pt-2">
            <!-- Slides -->
            <div
                class="card"
                v-for="(product, index) in promotionProducts"
                :key="index"
            >
                <div>
                    <img
                        :src="product.cover_photo"
                        :alt="product.title"
                        class="card-img-top cursor"
                        @click="toDetailPage(product.slug)"
                    />
                    <font-awesome-icon
                        v-show="user"
                        @click="toggleWish(product.id)"
                        :icon="['fas', 'heart']"
                        class="icon position-absolute rounded-circle bg-light"
                        :class="{ 'wish-list': hasInWishList(product.id) }"
                        :id="`active-wish-${product.id}`"
                    />
                    <div class="my-2 mx-2" @click="toDetailPage(product.slug)">
                        <p class="arrival-body" style="font-size: 14px;">
                            {{ product.title }}
                        </p>
                        <div class="d-flex flex-column flex-lg-row gap-1">
                            <!-- <p
                                v-if="
                                    product.productvariants[0].discounted_price
                                "
                                class="arrival-body fw-bold"
                            >
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0]
                                            .discounted_price,
                                    )
                                }}
                                MMK
                            </p> -->
                            <p
                                v-if="discountedVariant(product)"
                                class="arrival-body fw-bold"
                            >
                                {{
                                    addCommaForPrice(
                                        discountedVariant(product)
                                            .discounted_price,
                                    )
                                }}
                                MMK
                            </p>
                            <p
                                class="sold-out text-center"
                                v-if="soldOutedProducts.includes(product.id)"
                            >
                                Out of Stock
                            </p>
                        </div>
                        <div class="d-flex flex-column flex-lg-row gap-1">
                            <!-- <p
                                class="arrival-body"
                                :class="
                                    product.productvariants[0].discounted_price
                                        ? 'text-decoration-line-through'
                                        : 'fw-bold fs-6'
                                "
                                style="font-size: 14px;"
                            >
                                {{
                                    addCommaForPrice(
                                        product.productvariants[0].final_price,
                                    )
                                }}
                                MMK
                            </p> -->
                            <p
                                class="arrival-body"
                                :class="
                                    discountedVariant(product)
                                        ? 'text-decoration-line-through'
                                        : 'fw-bold fs-6'
                                "
                                style="font-size: 14px;"
                            >
                                {{
                                    addCommaForPrice(
                                        originalVariantPrice(product),
                                    )
                                }}MMK
                            </p>
                            <!-- <p
                                class="arrival-body"
                                style="font-size: 14px;"
                                v-if="
                                    product.productvariants[0]
                                        .discounted_type != 0
                                "
                            >
                                {{
                                    product.productvariants[0].discounted_amount
                                }}
                                {{
                                    product.productvariants[0]
                                        .discounted_type == 1
                                        ? '% OFF'
                                        : 'MMK OFF'
                                }}
                            </p> -->
                            <p
                                class="arrival-body"
                                style="font-size: 14px;"
                                v-if="discountedVariant(product)"
                            >
                                {{
                                    discountedVariant(product).discounted_amount
                                }}
                                {{
                                    discountedVariant(product)
                                        .discounted_type == 1
                                        ? '% OFF'
                                        : 'MMK OFF'
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal box -->
        <div
            v-if="notifyModal"
            class="modal notifyModal"
            tabindex="-1"
            aria-labelledby="notifyModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div
                        class="modal-body d-flex flex-column justify-content-center align-items-center"
                    >
                        <h3 class="mx-auto">
                            Promotion is not starting yet
                        </h3>
                        <span @click="notifyModal = false" class="btn"
                            >Close</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Slide from '@/utils/Slide';
import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';
import Countdown from '../reusable/Countdown';
import Products from '@/graphql/queries/product/Products.gql';
// apollo queries
import activePromotion from '@/graphql/queries/home/ActivePromotion.gql';

export default {
    mixins: [Slide, CommaPrice, AddToWishList],
    components: {
        'count-down': Countdown,
    },
    data() {
        return {
            soldOutedProducts: [],
            wishListColor: false,
            show: [],
            remainingHours: false,
            promotionProducts: [],
            notifyModal: false,
        };
    },
    mounted() {
        this.getActivePromotion();
    },
    computed: {
        ...mapGetters({
            activePromotion: 'promotion/getActivePromotion',
            user: 'auth/getUser',
            products: 'product/getProducts',
        }),
        timerProp() {
            if (new Date(this.activePromotion?.start_date) > new Date()) {
                return {
                    is_upcoming: true,
                    title: 'Starting In',
                    timer: this.activePromotion?.start_date
                        ?.split(' ')[0]
                        .concat(
                            'T',
                            this.activePromotion?.start_date?.split(' ')[1],
                        ),
                };
            } else {
                return {
                    is_upcoming: false,
                    title: 'Ending In',
                    timer: this.activePromotion?.end_date
                        ?.split(' ')[0]
                        .concat(
                            'T',
                            this.activePromotion.end_date?.split(' ')[1],
                        ),
                };
            }
        },
    },
    methods: {
        originalVariantPrice(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                )?.final_price ??
                prd.productvariants.find(pv => pv.total_in_stock > 0)
                    ?.final_price ??
                prd.productvariants[0]?.final_price
            );
        },
        discountedVariant(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                ) || null
            );
        },
        async getActivePromotion() {
            try {
                const promotions = await this.$apollo.query({
                    query: activePromotion,
                    fetchPolicy: 'no-cache',

                    variables: {
                        first: 1,
                        page: 1,
                    },
                });

                if (promotions) {
                    this.$store.commit(
                        'promotion/ADD_ACTIVE_PROMOTION',
                        promotions.data.activePromotion,
                    );
                    this.getProducts();
                }
            } catch (error) {
                console.log(error);
            }
        },
        getProducts() {
            this.$apollo
                .query({
                    query: Products,
                    fetchPolicy: 'no-cache',
                    variables: {
                        promotion_id: this.activePromotion?.id,
                    },
                })
                .then(response => {
                    this.total_count =
                        response.data.filterProducts.paginatorInfo.total;
                    this.filtered_count = this.total_count;
                    this.current_count =
                        response.data.filterProducts.paginatorInfo.count;
                    this.hasMorePages =
                        response.data.filterProducts.paginatorInfo.hasMorePages;
                    this.current_page = 1;

                    // this.$store.commit(
                    //     'product/ADD_PRODUCTS',
                    //     response.data.filterProducts,
                    // );

                    const products = response.data.filterProducts.data;
                    this.promotionProducts = products;
                    products.map(product => {
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock <= 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        hours(payload) {
            this.remainingHours = payload;
            console.log('payload', payload);
        },
        toPromotionPage() {
            this.$router.push({
                name: 'PromotionPage',
                query: {
                    promotion_id: this.activePromotion.id,
                },
            });
        },
        toDetailPage(slug) {
            if (this.timerProp.is_upcoming) {
                this.notifyModal = true;
            } else {
                this.$router.push({
                    // name: 'ProductDetail',
                    name: 'ProductVariantDetail',
                    params: {
                        slug: slug,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/button.scss';
.scrolling-wrapper {
    justify-content: center;
    gap: 1rem;
}
.card {
    width: 10rem;
    padding: 0;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    .icon {
        cursor: pointer;
        color: rgb(220, 217, 217);
        padding: 6px;
        width: 1.8rem;
        height: 1.8rem;
        top: 10px;
        right: 0px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 10rem;
    }
    .arrival-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .sold-out {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 50px;
        background: transparent
            linear-gradient(102deg, #e43225 0%, #ff5400 100%) 0% 0% no-repeat
            padding-box;
        opacity: 1;
    }
}
.promotion-title {
    text-align: center;
}
.notifyModal {
    display: block;
    background: #00000085;
    a {
        color: #000;
    }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .scrolling-wrapper {
        gap: 0rem;
        justify-content: start;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .card {
        width: 15rem;
        margin: 0 1rem 0 0;
        img {
            height: 12rem;
        }
    }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .promotion-title {
        text-align: left;
    }
}

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
// X-Large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
// @media (min-width: 1400px) { ... }
</style>
